import React, { useState } from 'react';
import { Link, graphql } from 'gatsby';
import { LayoutLp, SEO, AssetImage, LWrap } from '../../../components/_index';
import Slider from 'react-slick';
import { useMobile } from '../../../utils/use-series';
import '../../../assets/_sass/page/sp/afternoon.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;
  const isSp = useMobile();
  const settings = {
    dots: true,
    arrows: false,
    autoplay: false,
    speed: 1500,
  };

  // SPテキストの「続きを見る」をセクションごとに制御
  const [expandedSections, setExpandedSections] = useState<{
    [key: string]: boolean;
  }>({});

  const handleShowMore = (sectionId: string) => {
    setExpandedSections({
      ...expandedSections,
      [sectionId]: !expandedSections[sectionId],
    });
  };

  return (
    <LayoutLp isAfternoon={true}>
      <SEO
        title={frontmatter?.title}
        description={frontmatter?.description}
        ogimage={`${process.env.BASE_URL}/assets/images/sp/afternoon/ogimage.png`}
      />
      <main>
        <div className="p_afternoon">
          <section className="lp_kv">
            {!isSp ? (
              <figure className={`imgWrapper`}>
                <AssetImage
                  src="/assets/images/sp/afternoon/kv.png"
                  alt=""
                  loading="lazy"
                />
              </figure>
            ) : (
              <figure className={`imgWrapper`}>
                <AssetImage
                  src="/assets/images/sp/afternoon/kv__sp.png"
                  alt=""
                  loading="lazy"
                />
              </figure>
            )}
          </section>
          <div className="lp_kvLead">
            <div className="lp_kvLeadText">
              ロイヤルパークホテルズの
              <br className="u_sp" />
              「アフタヌーンティーセット」
              <br />
              各ホテル限定メニューをこの機会にぜひお楽しみください
            </div>
          </div>

          <section className="lp_sect u_pt100">
            <div className="lp_sectWrapper">
              <div className="lp_sectSlider">
              {!isSp ? (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider01.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider01_02.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider01_03.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider01_04.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider01_05.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider01_06.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider01_07.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                ) : (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider01__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider01_02__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider01_03__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider01_04__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider01_05__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider01_06__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider01_07__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                )}
              </div>
              <div className="lp_lead">
                <div className="lp_contentsNumber">
                  <span>01</span>ザ ロイヤルパークホテル アイコニック 大阪御堂筋
                </div>
                <div className="lp_setPlanTitle">
                  アフタヌーンティー
                  <br />
                  L'Automne
                </div>
                <p
                  className={`lp_sectLead lp_sectLead_showMore ${
                    expandedSections['section1'] ? 'expanded' : ''
                  }`}
                >
                  「La Belle Assiette（ラ ベル アシェット）」でご提供のシグネチャーディッシュのテリーヌもミニサイズで登場する秋の味覚とフレンチが融合したアフタヌーンティー。秋の彩りを添えた絶妙なマリアージュをご堪能ください。</p>
                {isSp && (
                  <span
                    className="show-more"
                    onClick={() => handleShowMore('section1')}
                  >
                    {expandedSections['section1']
                      ? '表示を減らす'
                      : '続きを見る'}
                  </span>
                )}
                <p className="lp_moreButton">
                  <Link
                    to="https://www.royalparkhotels.co.jp/ic/osakamidosuji/news/ft7nj6pfapap/"
                    target="_blank"
                  >
                    MORE
                    <i className="icon-arrow"></i>
                  </Link>
                </p>
              </div>
            </div>
          </section>

          <section className="lp_sect u_pt100">
            <div className="lp_sectWrapper lp_sectWrapper__reverse">
              <div className="lp_sectSlider">
              {!isSp ? (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider02.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider02_02.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider02_03.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider02_04.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                ) : (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider02__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider02_02__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider02_03__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider02_04__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                )}
              </div>
              <div className="lp_lead">
                <div className="lp_contentsNumber">
                  <span>02</span>横浜ロイヤルパークホテル　デリカ＆ラウンジ　コフレ
                </div>
                <div className="lp_setPlanTitle">
                  ランドマークアフタヌーンティー“Christmas”
                </div>
                <p
                  className={`lp_sectLead lp_sectLead_showMore ${
                    expandedSections['section2'] ? 'expanded' : ''
                  }`}
                >
                  華やかなリボンをあしらった横浜ランドマークタワー型のスタンドには、まるで絵本から飛び出してきたクリスマスの世界観が広がります。可愛らしいサンタクロースの帽子をかぶったクマのレアチーズケーキや雪だるまマカロンとクリスマス気分を♪</p>
                {isSp && (
                  <span
                    className="show-more"
                    onClick={() => handleShowMore('section2')}
                  >
                    {expandedSections['section2']
                      ? '表示を減らす'
                      : '続きを見る'}
                  </span>
                )}
                <p className="lp_moreButton">
                  <Link
                    to="https://www.yrph.com/restaurants/teatime/teatime/#coffret"
                    target="_blank"
                  >
                    MORE
                    <i className="icon-arrow"></i>
                  </Link>
                </p>
              </div>
            </div>
          </section>

          <section className="lp_sect">
            <div className="lp_sectWrapper">
              <div className="lp_sectSlider">
              {!isSp ? (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider03.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                ) : (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider03__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                )}
              </div>
              <div className="lp_lead">
                <div className="lp_contentsNumber">
                  <span>03</span>丸ノ内ホテル
                </div>
                <div className="lp_setPlanTitle">
                  秋のアフタヌーンティー
                </div>
                <p
                  className={`lp_sectLead lp_sectLead_showMore ${
                    expandedSections['section3'] ? 'expanded' : ''
                  }`}
                >
                  みずみずしいマスカットとソーテルヌワインの芳醇な香り漂うグラスデザートや青リンゴの風味豊かなコンポートをのせたタルトなど秋を彩る繊細なスイーツが並ぶアフタヌーンティー！芸術の秋を連想させるチェロのチョコレート細工も彩りを添えます。
                </p>
                {isSp && (
                  <span
                    className="show-more"
                    onClick={() => handleShowMore('section3')}
                  >
                    {expandedSections['section3']
                      ? '表示を減らす'
                      : '続きを見る'}
                  </span>
                )}
                <p className="lp_moreButton">
                  <Link
                    to="https://www.marunouchi-hotel.co.jp/tpx-afternoontea202409/"
                    target="_blank"
                  >
                    MORE
                    <i className="icon-arrow"></i>
                  </Link>
                </p>
              </div>
            </div>
          </section>

          <section className="lp_sect">
            <div className="lp_sectWrapper lp_sectWrapper__reverse">
              <div className="lp_sectSlider">
              {!isSp ? (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider04.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider04_02.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider04_03.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                ) : (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider04__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider04_02__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider04_03__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                )}
              </div>
              <div className="lp_lead">
                <div className="lp_contentsNumber">
                  <span>04</span>ザ ロイヤルパークホテル アイコニック 東京汐留
                </div>
                <div className="lp_setPlanTitle">
                  AFTERNOON TEA 
                  <br />
                  with SIROCCO 
                  <br />
                  「ホワイトクリスマス」
                </div>
                <p
                  className={`lp_sectLead lp_sectLead_showMore ${
                    expandedSections['section4'] ? 'expanded' : ''
                  }`}
                >
                  「ブリリアントホワイトクリスマス」な館内装飾に「ホワイトクリスマス」なアフタヌーンティー。ウェッジウッド「フェスティビティ」シリーズからほっと優しいオフホワイトのティーセットも初登場し、特別なクリスマス気分を盛りあげます。
                </p>
                {isSp && (
                  <span
                    className="show-more"
                    onClick={() => handleShowMore('section4')}
                  >
                    {expandedSections['section4']
                      ? '表示を減らす'
                      : '続きを見る'}
                  </span>
                )}
                <p className="lp_moreButton">
                  <Link
                    to="https://www.royalparkhotels.co.jp/ic/tokyoshiodome/news/0p1jfwdzu6/"
                    target="_blank"
                  >
                    MORE
                    <i className="icon-arrow"></i>
                  </Link>
                </p>
              </div>
            </div>
            {/* <div className="lp_leadSub">
              <figure className="imgWrapper">
                <AssetImage
                  src="/assets/images/sp/afternoon/sect02_sub.png"
                  alt=""
                  loading="lazy"
                />
              </figure>
              <p className="lp_sectLead">
                <span className="lp_sectLead_sub">
                  バレンタイン限定でご提供
                </span>
                デートや女子会に最適な大人かわいいバレンタイン限定アフタヌーンティーをお楽しみください。
                <span className="lp_moreButton">
                  <Link
                    to="https://www.rph.co.jp/restaurants/plan/rlrrbr8g6u/"
                    target="_blank"
                  >
                    MORE
                    <i className="icon-arrow"></i>
                  </Link>
                </span>
              </p>
            </div> */}
          </section>

          <section className="lp_sect">
            <div className="lp_sectWrapper">
              <div className="lp_sectSlider">
              {!isSp ? (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider05.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider05_02.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                ) : (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider05__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider05_02__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                )}
              </div>
              <div className="lp_lead">
                <div className="lp_contentsNumber">
                  <span>05</span>ロイヤルパークホテル（東京・日本橋）　ロビーラウンジ　フォンテーヌ
                </div>
                <div className="lp_setPlanTitle">
                  昭和ノスタルジックアフタヌーンティー
                </div>
                <p
                  className={`lp_sectLead lp_sectLead_showMore ${
                    expandedSections['section5'] ? 'expanded' : ''
                  }`}
                >
                  しっかりと固めに焼き上げたプリン・ア・ラ・モードやシュー生地を白鳥に見立てたスワンシュークリームなど、純喫茶をイメージしたメニューが勢揃い。耳に心地よい黒電話の音が聞こえてきそうなノスタルジックな世界観を感じてください。
                </p>
                {isSp && (
                  <span
                    className="show-more"
                    onClick={() => handleShowMore('section5')}
                  >
                    {expandedSections['section5']
                      ? '表示を減らす'
                      : '続きを見る'}
                  </span>
                )}
                <p className="lp_moreButton">
                  <Link
                      to="https://www.rph.co.jp/restaurants/plan/46tlbfdzwt/"
                      target="_blank"
                    >
                      MORE
                    <i className="icon-arrow"></i>
                  </Link>
                </p>
              </div>
            </div>
          </section>

          <section className="lp_sect">
            <div className="lp_sectWrapper lp_sectWrapper__reverse">
              <div className="lp_sectSlider">
              {!isSp ? (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider06.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider06_02.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider06_03.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                ) : (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider06__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider06_02__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider06_03__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                )}
              </div>
              <div className="lp_lead">
                <div className="lp_contentsNumber">
                  <span>06</span>ロイヤルパークホテル（東京・日本橋）　中国料理　桂花苑
                </div>
                <div className="lp_setPlanTitle">
                  チャイニーズアフタヌーンティー
                </div>
                <p
                  className={`lp_sectLead lp_sectLead_showMore ${
                    expandedSections['section6'] ? 'expanded' : ''
                  }`}
                >
                  人気スイーツのパンダ饅頭やぶたさん肉饅頭が愛嬌たっぷりの可愛さでお迎えします。色とりどりの点心や定番スイーツ、香り豊かな中国茶とともに日頃の忙しさを忘れ、ゆったりとした優雅な時間をお過ごしください。
                </p>
                {isSp && (
                  <span
                    className="show-more"
                    onClick={() => handleShowMore('section6')}
                  >
                    {expandedSections['section6']
                      ? '表示を減らす'
                      : '続きを見る'}
                  </span>
                )}
                <p className="lp_moreButton">
                  <Link
                    to="https://www.rph.co.jp/restaurants/plan/mo18kya7cs/"
                    target="_blank"
                  >
                    MORE
                    <i className="icon-arrow"></i>
                  </Link>
                </p>
              </div>
            </div>
            {/* <div className="lp_leadSub">
              <figure className="imgWrapper">
                <AssetImage
                  src="/assets/images/sp/afternoon/sect03_sub.png"
                  alt=""
                  loading="lazy"
                />
              </figure>
              <p className="lp_sectLead">
                <span className="lp_sectLead_sub">
                  さらにテイクアウトで楽しめる
                </span>
                  苺と桜のロールケーキを季節限定でお楽しみください
                <span className="lp_moreButton">
                  <Link
                    to=""
                    target="_blank"
                  >
                    MORE
                    <i className="icon-arrow"></i>
                  </Link>
                </span>
              </p>
            </div> */}
          </section>

          <section className="lp_sect">
            <div className="lp_sectWrapper">
              <div className="lp_sectSlider">
              {!isSp ? (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider07.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>                 
                    </Slider>
                  </>
                ) : (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider07__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>     
                    </Slider>
                  </>
                )}
              </div>
              <div className="lp_lead">
                <div className="lp_contentsNumber">
                  <span>07</span>仙台ロイヤルパークホテル
                </div>
                <div className="lp_setPlanTitle">
                  アフタヌーンティーセット
                </div>
                <p
                  className={`lp_sectLead lp_sectLead_showMore ${
                    expandedSections['section7'] ? 'expanded' : ''
                  }`}
                >
                 定番の苺のショートケーキに、濃厚なマスカルボーネ香るティラミスパイ、干し柿のマカロンなど秋のエッセンスももちろん添えた多彩なアイテムをご用意。澄んだ空気と秋色に染まるガーデンを眺めながら、贅沢な午後のひとときをお過ごしください。
                </p>
                {isSp && (
                  <span
                    className="show-more"
                    onClick={() => handleShowMore('section7')}
                  >
                    {expandedSections['section7']
                      ? '表示を減らす'
                      : '続きを見る'}
                  </span>
                )}
                <p className="lp_moreButton">
                  <Link
                      to="https://www.srph.co.jp/pickup/5qh4kt7ew/"
                      target="_blank"
                    >
                    MORE
                    <i className="icon-arrow"></i>
                  </Link>
                </p>
              </div>
            </div>
            {/* <div className="lp_leadSub">
              <figure className="imgWrapper">
                <AssetImage
                  src="/assets/images/sp/afternoon/sect04_sub.png"
                  alt=""
                  loading="lazy"
                />
              </figure>
              <p className="lp_sectLead">
                <span className="lp_sectLead_sub u_tac">
                  さらにテイクアウトで楽しめる
                </span>
                わらび餅粉の入ったしっとり・もちもちの生地に苺とマスカルポーネクリームのさっぱりとした味わいです。
                <span className="lp_moreButton">
                  <Link
                    to="https://www.srph.co.jp/pickup/ifvefyjko/"
                    target="_blank"
                  >
                    MORE
                    <i className="icon-arrow"></i>
                  </Link>
                </span>
              </p>
            </div> */}
          </section>

          {/* <section className="lp_sect">
            <div className="lp_sectWrapper lp_sectWrapper__reverse">
              <div className="lp_sectSlider">
              {!isSp ? (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider08.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                ) : (
                  <>
                    <Slider {...settings} className="slider">
                      <div>
                        <AssetImage
                          src="/assets/images/sp/afternoon/slider08__sp.png"
                          alt=""
                          loading="lazy"
                        />
                      </div>
                    </Slider>
                  </>
                )}
              </div>
              <div className="lp_lead">
                <div className="lp_contentsNumber">
                  <span>08</span>ザ ロイヤルパークキャンバス 札幌大通公園
                </div>
                <div className="lp_setPlanTitle">
                  KAMUY パークビューアフタヌーンティー 
                  <br />
                  ～ちょっぴり大人なダークなひととき～
                </div>
                <p
                  className={`lp_sectLead lp_sectLead_showMore ${
                    expandedSections['section8'] ? 'expanded' : ''
                  }`}
                >
                  秋風凍る札幌にちょっぴりダークなアフタヌーンティー。さっぽろテレビ塔を望むラウンジで、ほっこり甘い秋の味覚のスイーツとホッと温まるドリンクを。スパークリングワインとのマリア―ジュもぜひお試しください。
                </p>
                {isSp && (
                  <span
                    className="show-more"
                    onClick={() => handleShowMore('section8')}
                  >
                    {expandedSections['section8']
                      ? '表示を減らす'
                      : '続きを見る'}
                  </span>
                )}
                <p className="lp_moreButton">
                  <Link
                    to="https://www.royalparkhotels.co.jp/canvas/sapporoodoripark/news/vkmwi72q02ad/"
                    target="_blank"
                  >
                    MORE
                    <i className="icon-arrow"></i>
                  </Link>
                </p>
              </div>
            </div>
          </section> */}

          <section>
            <div className="instagram">
              <LWrap>
                <Link
                  to="https://www.instagram.com/royalparkhotels__official/"
                  target="_blank"
                >
                  <AssetImage
                    src="/assets/images/sp/afternoon/instagram.png"
                    alt=""
                    loading="lazy"
                  />
                </Link>
              </LWrap>
            </div>
          </section>
        </div>
      </main>
    </LayoutLp>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
